<template>
    <div>
        <!-- 头部 -->
        <div class="orderhead">
            <div class="fanhui">
                <img @click="funfan" src="../logo/fanhui.png" alt="">
                <span>订单查询</span>
            </div>
            <div class="content">
                <img class="one" src="../logo/chaxun.png" alt="">
                <img class="two" src="../logo/xiaofei.png" alt="">
            </div>
        </div>
        <!-- 内容 -->
        <div class="csscontent">
            <div class="sousuo">
                <div class="title">订单查询</div>
                <div class="describe">请输入查询密码</div>
                <div class="neirong">
                    <el-input v-model="sbjpass" placeholder="请输入查询密码" size="small">
                        <i slot="prefix" class="el-input__icon el-icon-search"></i>
                    </el-input>
                    <div class="btn" @click="fundetail()">订单查询</div>
                </div>
            </div>
            <div class="jieguo" v-if="objcha != ''">
                <div class="qun">
                    <div class="left">
                        <img src="../logo/dianbao.png" alt="">
                        <span>点击加入电报群按钮，即可跳转到VIP电报群</span>
                    </div>
                    <div class="right">
                        <div class="btn" @click="tiao">
                            <span>进入电报群</span>
                            <img src="../logo/jiantou.png" alt="">
                        </div>
                    </div>
                </div>
                <div class="xuxian"></div>
                <div class="kami">
                    <div class="left">
                        <span class="ka">卡密：</span>
                        <span class="mi">{{ objcha.shop_url }}</span>
                    </div>
                    <div class="right copy-btn" :data-clipboard-text="objcha.shop_url" @click="copy()">
                        复制
                    </div>
                </div>
                <div class="tishiss">点击复制卡密按钮，粘贴到浏览器即可跳转到VIP电报群</div>
            </div>
        </div>





        <!-- 遮罩层 -->
        <div class="zhe" v-if="showzhe">
            <img src="../logo/jiazai.png" alt="">
        </div>
        <!-- 提示框 -->
        <div class="tishi" :class="[showtishi ? 'tishis' : '']" v-html="tishi"></div>
    </div>
</template>


<script>
/* 
  detail 查询订单
*/
import { detail } from "../units/api.js"
import ClipboardJS from 'clipboard';
export default {
    data() {
        return {
            sbjpass: '', //输入框的内容
            showtishi: false, //提示的判断
            tishi: '',//提示的内容
            showzhe: false,//遮罩层的判断
            objcha: '',//查询到的数据
        };
    },


    created() {

    },
    methods: {
        //返回
        funfan() {
            this.$router.go(-1)
        },
        //查询订单
        fundetail() {
            //把提示关闭
            this.showtishi = false
            //查询前查看内容是否为空
            if (this.sbjpass == '' || this.sbjpass == null || this.sbjpass == undefined) {
                this.funti('订单号不能为空')
                return
            }

            //每次查询打开
            this.objcha = ''
            //打开遮罩层
            this.showzhe = true

            //查询订单
            detail({ order_num: this.sbjpass }).then(res => {
                console.log(res)
                this.showzhe = false
                if (res.code == 1) {
                    this.objcha = res.data
                } else {
                    this.funti('未检测到付款信息')
                }
            }).catch(() => {
                this.funti('查询超时')

                this.showzhe = false
            })
        },
        //定时显示的提示框
        funti(e) {
            this.tishi = e
            this.showtishi = true
            let a = setInterval(() => {
                this.showtishi = false
                clearInterval(a);
            }, 2000)

        },

        //跳转
        tiao() {
            // window.location.href = this.objcha.shop_url
            window.open(this.objcha.shop_url)

        },

        //复制
        copy() {
            // let a = this.objcha.shop_url
            //    new clipboard.writeText(this.objcha.shop_url);
            let a = new ClipboardJS('.copy-btn');
            // console.log(new ClipboardJS('.copy-btn'));
            a.on('success', (e) => {
                console.log(e);

                this.funti('复制成功')
            })
            a.on('error', (e) => {
                console.log(e);

                this.funti('复制失败，请手动复制')
            })

        }

    },

};
</script>
<style scoped lang="scss">
@media screen and (min-width: 1200px) {

    // 头部
    .orderhead {
        width: 100%;
        height: 200px;
        background-color: #3369FF;


        .fanhui {
            position: fixed;
            top: -100000px;
        }

        .content {
            width: 1160px;
            margin: 0 auto;
            padding-top: 38px;
            display: flex;
            align-items: center;
            padding-left: 10px;
            box-sizing: border-box;

            .one {

                width: 90px;
                height: 90px;
            }

            .two {
                width: 400px;

            }
        }
    }

    // 内容
    .csscontent {
        margin: 0 auto;
        width: 1160px;
        margin-top: -56px;

        // 搜索框
        .sousuo {
            background-color: #ffffff;
            width: 100%;
            box-sizing: border-box;
            padding: 40px 50px;
            border-radius: 12px;
            box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);

            .title {
                font-size: 20px;
                font-weight: bold;
                line-height: normal;
                letter-spacing: 0em;
                font-variation-settings: "opsz" auto;
                font-feature-settings: "kern" on;
                color: #272D2B;
            }

            .describe {
                font-family: Source Han Sans;
                font-size: 16px;
                font-weight: normal;
                line-height: normal;
                text-align: justify;
                /* 浏览器可能不支持 */
                letter-spacing: 0em;
                font-variation-settings: "opsz" auto;
                font-feature-settings: "kern" on;
                color: #A8A8A9;
                margin-top: 10px;
            }

            .neirong {
                margin-top: 16px;
                position: relative;

                ::v-deep .el-icon-search {
                    font-size: 20px !important;
                }
            }

            .btn {
                width: 135px;
                height: 42px;
                border-radius: 8px;
                background: #2E62F0;
                font-family: Source Han Sans;
                font-size: 16px;
                font-weight: bold;
                letter-spacing: 0em;

                font-variation-settings: "opsz" auto;
                font-feature-settings: "kern" on;
                color: #FFFFFF;
                line-height: 42px;
                text-align: center;
                cursor: pointer;
                position: absolute;
                top: 7px;
                right: 7px;
            }
        }

        // 展示
        .jieguo {
            width: 1160px;
            margin-top: 20px;
            background-color: #FFFFFF;
            border-radius: 12px;
            background: #FFFFFF;
            box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
            padding: 35px 50px;
            box-sizing: border-box;

            .qun {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .left {
                    display: flex;
                    align-items: center;

                    img {
                        width: 56px;
                    }

                    span {
                        margin-left: 16px;
                        font-family: Source Han Sans;
                        font-size: 16px;
                        font-weight: bold;
                        line-height: normal;
                        text-align: justify;
                        letter-spacing: 0em;
                        font-variation-settings: "opsz" auto;
                        font-feature-settings: "kern" on;
                        color: #272D2B;
                    }
                }

                .right {
                    display: flex;
                    align-items: center;

                    .btn {
                        border-radius: 528px;
                        background: #F53D4F;
                        box-shadow: 0px 2px 4px 0px rgba(254, 43, 57, 0.2);
                        padding: 10px 20px;
                        display: flex;
                        font-size: 16px;
                        color: #FFFFFF;
                        display: flex;
                        align-items: center;
                        cursor: pointer;

                        img {
                            margin-left: 5px;
                            width: 16px;
                            height: 16px;
                            margin-top: 4px;
                        }
                    }
                }
            }

            .xuxian {
                width: 100%;
                margin: 28px 0;
                height: 1px;
                background: repeating-linear-gradient(to right, #BCBCBC, #BCBCBC 5px, transparent 1px, transparent 10px);
            }

            .kami {
                padding: 8px 8px 8px 26px;
                background-color: #F9FAFF;
                margin-bottom: 16px;
                border-radius: 8px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .left {
                    font-size: 16px;
                    word-wrap: break-word;
                    word-break: normal;
                    width: 940px;

                    .ka {
                        color: #333333;
                        cursor: default;
                    }

                    .mi {
                        color: #2F8FD2
                    }
                }

                .right {
                    cursor: pointer;
                    border-radius: 8px;
                    background: #F53D4F;
                    box-shadow: 0px 2px 4px 0px rgba(254, 43, 57, 0.2);
                    padding: 8px 24px;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: normal;
                    letter-spacing: 0em;
                    font-variation-settings: "opsz" auto;
                    font-feature-settings: "kern" on;
                    color: #FFFFFF;
                }
            }

            .tishiss {
                font-family: Source Han Sans;
                font-size: 14px;
                font-weight: normal;
                line-height: normal;
                text-align: justify;
                letter-spacing: 0em;
                font-variation-settings: "opsz" auto;
                font-feature-settings: "kern" on;
                color: #A8A8A9;
            }
        }
    }

    ::v-deep .el-input__inner {
        height: 54px;
        font-size: 18px;
    }



    //遮罩层
    .zhe {
        background-color: rgba(0, 0, 0, 0.7);
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 999;
        text-align: center;

        img {
            position: absolute;
            width: 100px;
            top: 50vh;
            left: 50vw;
            margin-top: -50px;
            margin-left: -50px;
            transform-origin: 50px 50px;
            animation: slidein 2s linear infinite;
        }

        @keyframes slidein {
            0% {
                transform: rotate(0deg);
            }

            50% {
                transform: rotate(180deg);
            }

            100% {
                transform: rotate(360deg);
            }
        }

    }

    // 提示
    .tishi {
        cursor: default;
        position: fixed;
        left: 50%;
        top: -10vh;
        transform: translate(-50%, -50%);
        font-family: Source Han Sans;
        font-size: 20px;
        z-index: 999;
        color: #ffffff;
        background-color: rgba(0, 0, 0, 0.8);
        padding: 20px 40px;
        // min-height: 54px;
        // line-height: 54px;
        border-radius: 3rem;
        transition: all 0.2s ease-in-out;
    }

    .tishis {
        top: 50vh;
    }
}



@media screen and (max-width: 1200px) {

    // 关闭默认点击样式
    div {
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;

    }

    // 头部
    .orderhead {
        width: 375rem;
        height: 188rem;
        background-color: #3369FF;


        .fanhui {
            padding: 20rem 0rem 10rem;
            position: relative;
            width: 375rem;
            display: flex;

            img {
                position: absolute;
                width: 25rem;
                left: 15rem;
            }

            span {
                width: 375rem;
                font-family: Source Han Sans;
                font-size: 19rem;
                font-weight: 700;
                color: #FFFFFF;
                text-align: center;
            }
        }

        .content {
            width: 375rem;
            margin: 0 auto;
            // padding-top: 38px;
            display: flex;
            align-items: center;
            padding-left: 10rem;
            box-sizing: border-box;

            .one {

                width: 60rem;
                height: 60rem;
            }

            .two {
                width: 250rem;

            }
        }
    }


    // 内容
    .csscontent {
        margin: 0 auto;
        width: 347rem;
        margin-top: -56px;

        // 搜索框
        .sousuo {
            background-color: #ffffff;
            width: 100%;
            box-sizing: border-box;
            padding: 12rem;
            border-radius: 12rem;
            box-shadow: 0rem 2rem 8rem 0rem rgba(0, 0, 0, 0.1);

            .title {
                font-size: 15rem;
                font-weight: bold;
                line-height: normal;
                letter-spacing: 0em;
                font-variation-settings: "opsz" auto;
                font-feature-settings: "kern" on;
                color: #272D2B;
            }

            .describe {
                font-family: Source Han Sans;
                font-size: 12rem;
                font-weight: normal;
                line-height: normal;
                text-align: justify;
                letter-spacing: 0em;
                font-variation-settings: "opsz" auto;
                font-feature-settings: "kern" on;
                color: #A8A8A9;
                margin-top: 5rem;
            }

            .neirong {
                margin-top: 10rem;
                position: relative;
                display: flex;

                // justify-content: space-between;
                ::v-deep .el-input {
                    height: 32rem;
                    width: 240rem;
                }

                ::v-deep .el-input__inner {

                    height: 32rem;
                    font-size: 12rem;
                    padding-left: 24rem;
                    width: 240rem;
                }

                ::v-deep .el-input__icon {
                    font-size: 12rem;
                    line-height: 32rem;
                    width: 18rem;
                }


            }

            .btn {
                margin-left: 10rem;
                width: 72rem;
                height: 32rem;
                border-radius: 8px;
                background: #2E62F0;
                font-family: Source Han Sans;
                font-size: 12rem;
                font-weight: bold;
                letter-spacing: 0em;

                font-variation-settings: "opsz" auto;
                font-feature-settings: "kern" on;
                color: #FFFFFF;
                line-height: 32rem;
                text-align: center;
                cursor: pointer;
                // position: absolute;
                // top: 6rem;
                // right: 7rem;
            }
        }

        // 展示
        .jieguo {
            width: 347rem;
            margin-top: 10rem;
            background-color: #FFFFFF;
            border-radius: 12rem;
            background: #FFFFFF;
            box-shadow: 0rem 2rem 8rem 0rem rgba(0, 0, 0, 0.1);
            padding: 16rem 12rem;
            box-sizing: border-box;

            .qun {
                display: flex;
                justify-content: space-between;
                align-items: center;
                position: relative;

                .left {
                    display: flex;
                    align-items: flex-end;

                    img {
                        width: 56rem;
                    }

                    span {
                        font-size: 12rem;
                        color: #7D7D7D;
                        margin-left: 10rem;
                    }
                }

                .right {
                    display: flex;
                    align-items: center;

                    .btn {
                        position: absolute;
                        top: 2rem;
                        left: 69rem;
                        border-radius: 6rem;
                        background: #F53D4F;
                        box-shadow: 0px 2px 4px 0px rgba(254, 43, 57, 0.2);
                        padding: 6rem 12rem;
                        display: flex;
                        font-size: 12rem;
                        color: #FFFFFF;
                        display: flex;
                        align-items: center;
                        cursor: pointer;

                        img {
                            margin-left: 5rem;
                            width: 11rem;
                            height: 11rem;
                            margin-top: 1rem;
                        }
                    }
                }
            }

            .xuxian {
                width: 100%;
                margin: 15rem 0;
                height: 1rem;
                background: repeating-linear-gradient(to right, #BCBCBC, #BCBCBC 5px, transparent 1px, transparent 10px);
            }

            .kami {
                padding: 10rem;
                background-color: #F9FAFF;
                margin-bottom: 8rem;
                border-radius: 8rem;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .left {
                    font-size: 12rem;
                    width: 245rem;
                    word-wrap: break-word;
                    word-break: normal;

                    .ka {
                        color: #333333;
                        cursor: default;
                    }

                    .mi {
                        color: #2F8FD2
                    }
                }

                .right {
                    cursor: pointer;
                    border-radius: 8rem;
                    background: #F53D4F;
                    box-shadow: 0rem 2rem 4rem 0rem rgba(254, 43, 57, 0.2);
                    padding: 5rem 12rem;
                    font-size: 12rem;
                    font-weight: 500;
                    line-height: normal;
                    letter-spacing: 0em;
                    font-variation-settings: "opsz" auto;
                    font-feature-settings: "kern" on;
                    color: #FFFFFF;
                }
            }

            .tishiss {
                font-family: Source Han Sans;
                font-size: 12rem;
                font-weight: normal;
                line-height: normal;
                text-align: justify;
                letter-spacing: 0em;
                font-variation-settings: "opsz" auto;
                font-feature-settings: "kern" on;
                color: #A8A8A9;
            }
        }
    }




    // 提示
    .tishi {
        cursor: default;
        position: fixed;
        left: 50%;
        top: -10vh;
        transform: translate(-50%, -50%);
        font-family: Source Han Sans;
        font-size: 16rem;
        z-index: 999;
        color: #ffffff;
        background-color: rgba(0, 0, 0, 0.8);
        padding: 15rem;
        border-radius: 5rem;
        transition: all 0.3s ease-in-out;
        width: 200rem;
        text-align: center;
    }

    .tishis {
        // top: 160rem;
        top: 50vh;

    }

    //遮罩层
    .zhe {
        background-color: rgba(0, 0, 0, 0.7);
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 999;
        text-align: center;

        img {
            position: absolute;
            width: 100rem;
            top: 50vh;
            left: 50vw;
            margin-top: -50rem;
            margin-left: -50rem;
            transform-origin: 50rem 50rem;
            animation: slidein 2s linear infinite;
        }

        @keyframes slidein {
            0% {
                transform: rotate(0deg);
            }

            50% {
                transform: rotate(180deg);
            }

            100% {
                transform: rotate(360deg);
            }
        }

    }

}
</style>